import React, { useState } from "react";
import { FaInstagram, FaWhatsapp, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import Home from "./Pages/Home/Home";
import Navbar from "./Components/Header/Navbar";
import ScrollToTop from "./ScrollToTop";
import { Navigate, Route, Routes } from "react-router-dom";
import Education from "./Pages/Education/Education";
import SchoolPrograms from "./Components/School Programs/SchoolPrograms";
import Projects from "./Pages/Projects/Projects";
import Lab from "./Pages/Lab/Lab";
import About from "./Pages/About/About";
import Values from "./Pages/Values/Values";
import VissionAndMission from "./Pages/Vission & Mission/VissionAndMission";
import ContactUs from "./Pages/Contact Us/ContactUs";
import AIandMLLAB from "./Components/Ai&Ml_Lab/AMLab";
import SRLab from "./Components/Stem&RoboticsLab/SRLab";
import Footer from "./Components/Footer/Footer";
import AviAndAeroLab from "./Components/Aviation & Aero Lab/New/AviAndAeroLab";
import ProductsList from "./Pages/Products/ProductsList";
import ProductDetail from "./Components/Product/ProductDetail";
import OurGovernance from "./Pages/Our Governance/OurGovernance";
import { BsTwitterX } from "react-icons/bs";
import RCPlane from "./Pages/Projects/Pages/Aviation And Aerospace/RCPlane";
import Glider from "./Pages/Projects/Pages/Aviation And Aerospace/Glider";
import Drone from "./Pages/Projects/Pages/Aviation And Aerospace/Drone";
import CanSat from "./Pages/Projects/Pages/Aviation And Aerospace/CanSat";
import IOT from "./Pages/Projects/Pages/Stem And Robotics/IOT";
import Robotics from "./Pages/Projects/Pages/Stem And Robotics/Robotics";
import AdvancedRobotics from "./Pages/Projects/Pages/Stem And Robotics/AdvancedRobotics";
import Coding from "./Pages/Projects/Pages/Stem And Robotics/Coding";
import MachineLearning from "./Pages/Projects/Pages/Ai And Ml/MachineLearning";
import ArtificialIntelligence from "./Pages/Projects/Pages/Ai And Ml/ArtificialIntelligence";
import DIYProjects from "./Pages/Projects/Pages/Stem And Robotics/DIYProjects";
import DataAnalytics from "./Pages/Projects/Pages/Ai And Ml/DataAnalytics";
import ARVRTech from "./Pages/Projects/Pages/Stem And Robotics/ARVRTech";
import NLP from "./Pages/Projects/Pages/Ai And Ml/NLP";
import NeuralNetworkDeepLearning from "./Pages/Projects/Pages/Ai And Ml/NeuralNetworkDeepLearning";
import HandsOnProjectsInAIML from "./Pages/Projects/Pages/Ai And Ml/HandsOnProjectsInAIML";
import FlappingWingUAV from "./Pages/Projects/Pages/Aviation And Aerospace/FlappingWingUAV";
import CADAeromodelling from "./Pages/Projects/Pages/Aviation And Aerospace/CADAeromodelling";
// import WinterWorkshop from "./Pages/Landing/WinterWorkshop";
// import Try from "./Pages/Landing/Try";
// import ApplicationNew from "./Pages/Landing/ApplicationNew";
import GalleryNew from "./Pages/Gallery/GalleryNew";
// import ContactUsNew from "./Pages/Contact Us/ContactUsNew";

function App() {

  const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

  return (
    <div>
      <Navbar setBackgroundBlur={setIsBackgroundBlurred} />
      <div id="background" className={`xl:pt-12 max-sm:pt-12 relative z-0 ${isBackgroundBlurred ? '' : ''}`}>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/education"
            element={<Education />}
          />
          <Route
            path="/school-programs"
            element={<SchoolPrograms />}
          />
          <Route
            path="/aviation-and-aero-lab"
            element={<AviAndAeroLab />}
          />
          <Route
            path="/projects"
            element={<Projects />}
          />
          <Route
            path="/stem-and-robotics"
            element={<SRLab />}
          />
          <Route
            path="/ai-and-ml"
            element={<AIandMLLAB />}
          />
          <Route
            path="/lab"
            element={<Lab />}
          />
          <Route
            path="/about-us"
            element={<About />}
          />
          <Route
            path="/our-values"
            element={<Values />}
          />
          <Route
            path="/vission-mission"
            element={<VissionAndMission />}
          />
          <Route
            path="/contact"
            element={<ContactUs />}
          />
          <Route
            path="/products"
            element={<ProductsList />}
          />
          <Route
            path="/product-detail/:id"
            element={<ProductDetail />}
          />
          <Route
            path="/gallery"
            element={<GalleryNew />}
          />
          <Route
            path="/our-governance"
            element={<OurGovernance />}
          />
          <Route
            path="/projects/rc-plane"
            element={<RCPlane />}
          />
          <Route
            path="/projects/glider"
            element={<Glider />}
          />
          <Route
            path="/projects/drone"
            element={<Drone />}
          />
          <Route
            path="/projects/cansat"
            element={<CanSat />}
          />
          <Route
            path="/projects/IOT"
            element={<IOT />}
          />
          <Route
            path="/projects/robotics"
            element={<Robotics />}
          />
          <Route
            path="/projects/advanced-robotics"
            element={<AdvancedRobotics />}
          />
          <Route
            path="/projects/coding"
            element={<Coding />}
          />
          <Route
            path="/projects/machine-learning"
            element={<MachineLearning />}
          />
          <Route
            path="/projects/artificial-intelligence"
            element={<ArtificialIntelligence />}
          />
          <Route
            path="/projects/diy-projects"
            element={<DIYProjects />}
          />
          <Route
            path="/projects/data-analytics"
            element={<DataAnalytics />}
          />
          <Route
            path="/projects/ar-vr-tech"
            element={<ARVRTech />}
          />
          <Route
            path="/projects/natural-language-processing"
            element={<NLP />}
          />
          <Route
            path="/projects/neural-network-deep-learning"
            element={<NeuralNetworkDeepLearning />}
          />
          <Route
            path="/projects/hands-on-projects-in-ai-ml"
            element={<HandsOnProjectsInAIML />}
          />
          <Route
            path="/projects/flapping-wing-UAV"
            element={<FlappingWingUAV />}
          />
          <Route
            path="/projects/cad-aeromodeling"
            element={<CADAeromodelling />}
          />
          {/* <Route
            path="/contact"
            element={<ContactUsNew />}
          /> */}
          {/* <Route
            path="/winter-workshop"
            element={<WinterWorkshop />}
          />
          <Route
            path="/winter-workshop/try"
            element={<Try />}
          />
          <Route
            path="/winter-workshop/enrollment"
            element={<ApplicationNew />}
          /> */}
          <Route
            path="*"
            element={<Navigate to='/' />}
          />
        </Routes>
        <Footer />

        <div className="fixed top-1/2 right-4 space-y-4 z-50">
          <a
            href="https://www.linkedin.com/company/mh-intellect/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 transition-colors"
          >
            <FaLinkedinIn size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
          </a>
          <a
            href="https://x.com/MHIntellect5387"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black transition-colors"
          >
            <BsTwitterX size={30} className="my-3 bg-gray-500 bg-opacity-75 p-1 rounded" />
          </a>
          <a
            href="https://www.instagram.com/mh_intellect/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 hover:text-pink-700 transition-colors"
          >
            <FaInstagram size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
          </a>
          <a
            href="https://www.youtube.com/@mhintellect"
            target="_blank"
            rel="noopener noreferrer"
            className="text-red-600 hover:text-red-700 transition-colors"
          >
            <FaYoutube size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
          </a>
          <a
            href="https://wa.me/966583584111"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-500 hover:text-green-600 transition-colors"
          >
            <FaWhatsapp size={30} className="my-3 bg-black dark:bg-gray-500 bg-opacity-75 p-1 rounded" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;



// import React from 'react';

// const courses = [
//   "C Programming",
//   "Java",
//   "Javascript Zero to Hero",
//   "Step into Robotic Process Automation",
//   "Python - IITM Pravartak Certified",
//   "Python - IITM Pravartak Certified",
//   "Python - IITM Pravartak Certified",
//   "Python - IITM Pravartak Certified",
//   "Python - IITM Pravartak Certified",
//   "Python - IITM Pravartak Certified",
//   "Python - IITM Pravartak Certified",
//   "Python - IITM Pravartak Certified",
//   "Python - IITM Pravartak Certified",
//   "Python - IITM Pravartak Certified",
//   "RPA Design & Development V2.0",
//   "Chatgpt for Everyone",
//   "ChatGPT for Everyone Bengali",
//   "Introduction to Data Engineering and Bigdata",
//   "TestNG Framework for Selenium",
//   "Adobe Photoshop",
//   "Arduino",
//   "Ethical Hacking for Beginners",
//   "Flutter",
//   "Mastering Figma: Beginner to Expert UI/UX Design",
//   "Microsoft Powerpoint",
//   "Microsoft Word",
//   "MongoDB",
//   "MS Excel",
//   "Raspberry Pi for Beginners",
//   "Social Media Strategy - Instagram",
//   "Web Scraping",
//   "AutoCAD Civil",
//   "HTML & CSS",
//   "Java",
//   "SolidWorks",
//   "UX Fundamentals",
//   "Angular",
//   "Aptitude",
//   "Building REST APIs using .NET Core",
//   "Building REST APIs using .NET Core",
//   "C Programming",
//   "C++ Interview QnA",
//   "Data Science with R",
//   "Design Pattern",
//   "FSD 101",
//   "FSD 101 - Tamil",
//   "Fundamentals of DevOps",
//   "GIT",
//   "GIT",
//   "GIT in Bengali",
//   "GIT in Tamil",
//   "GIT in Telugu",
//   "Introduction to Datascience with R",
//   "Introduction to Datascience with R",
//   "Introduction to Datascience with R",
//   "Introduction to Datascience with R",
//   "MongoDB",
//   "MySQL",
//   "PL/SQL in Bengali",
//   "Product Management Interview Preparation",
//   "React Native",
//   "Selenium Automation with Python",
//   "Spring",
//   "Tableau - Introduction to Beginners",
//   "Typescript",
//   "Vue.js",
//   "HTML & CSS",
//   "Python Zero to Hero Malayalam",
//   "3ds Max and V-ray - Advanced",
//   "3ds Max and V-ray - Beginner",
//   "3ds Max and V-ray - Expert",
//   "3ds Max and V-ray - Intermediate",
//   "Adobe Illustrator Foundations",
//   "Advanced Digital Marketing Strategies",
//   "Advanced JavaScript",
//   "Advanced RPA",
//   "Advanced RPA Projects",
//   "Agile Fundamentals",
//   "Agile Fundamentals - Hindi",
//   "Agile Fundamentals in Tamil",
//   "AI Fundamentals",
//   "Angular",
//   "Angular Basics",
//   "Angular For Beginners",
//   "Angular Unit Testing",
//   "Ansible",
//   "Apache Cassandra With Python",
//   "Application Development using Microservices & Serverless",
//   "Application Development using Microservices and Serverless in Tamil",
//   "Applied NLP Project",
//   "Arduino Electronics in Tamil",
//   "Art of Electronics: Analog Electronics Part 2",
//   "Art of Electronics: Analog Electronics Part 1",
//   "Art of Electronics: Digital Electronics Part 1",
//   "AutoCAD Mechanical",
//   "Automating AWS with Python and Boto3",
//   "AWS Billing,Key Management & Security",
//   "AWS Fundamentals",
//   "AWS Fundamentals",
//   "AWS Infrastructure - Advanced",
//   "AWS Infrastructure - Intermediate",
//   "AWS Infrastructure for Beginners",
//   "AWS Security and Management",
//   "AZ 500: Data and App Security",
//   "AZ 500: Identity and Access Management",
//   "AZ 500: Platform Security",
//   "AZ 500: Security Operations",
//   "AZ-900",
//   "Azure DevOps",
//   "Azure Devops",
//   "Azure Devops Odia",
//   "Basics of AWS cloud computing",
//   "Become an IOS Developer from scratch",
//   "Brand Identity Design",
//   "Business Finance",
//   "C Programming in Hindi",
//   "C programming Interview QnA",
//   "C++ Beginners - Malayalam",
//   "C++ Programming for Beginners",
//   "C++ Programming for Beginners Bengali",
//   "C++ Programming for Beginners in Telugu",
//   "C++ Programming for Beginners Tamil",
//   "ChatGPT for Programmers",
//   "Cobol Basics",
//   "COBOL Intermediate",
//   "Computer Vision with Python",
//   "Creo Parametric",
//   "DALLE",
//   "Dalle - Tamil",
//   "Dalle in Arabic",
//   "Dalle in French",
//   "DART for Beginners",
//   "Data Analytics using Pandas in Arabic",
//   "Data Analytics using pandas in French",
//   "Data Analytics using Pandas in Hindi",
//   "Data analytics using pandas in swahili",
//   "Data Visualization with Matplotlib using Python in Swahili",
//   "Deep Learning Fundamentals",
//   "Deep Learning with TensorFlow",
//   "Design Guidelines using Figma",
//   "Design Guidelines Using Figma in Tamil",
//   "Docker",
//   "Docker - Hindi",
//   "Email Etiquette",
//   "Essential Marketing Tools and Techniques",
//   "Ethical Hacking Advanced - Tamil",
//   "Ethical Hacking for Beginners - Bengali",
//   "Ethical Hacking for Beginners - Hindi",
//   "Ethical Hacking for Beginners in Tamil",
//   "Express.js",
//   "FastAPI",
//   "Flask",
//   "Flutter Bengali",
//   "Flutter in Hindi",
//   "Flutter in Tamil",
//   "Foundations of Digital Marketing",
//   "Functional Programming in JS",
//   "Functional Programming in JS",
//   "Fundamentals of Semiconductors",
//   "Fundamentals of Semiconductors",
//   "Fundamentals of Semiconductors in Vietnamese",
//   "GATE CS Essentials",
//   "Gate CS Essentials",
//   "Generative AI",
//   "Generative AI in Bengali",
//   "Generative AI in Hindi",
//   "Generative AI in Kannada",
//   "Generative AI in Malayalam",
//   "Generative AI in Marathi",
//   "Generative AI in Tamil",
//   "Generative AI in Telugu",
//   "GitHub Actions",
//   "GitHub Project",
//   "Google Assistant Using Nodemcu",
//   "Google Cloud Platform",
//   "Home Automation using esp32",
//   "Home Automation using esp32",
//   "HTML & CSS",
//   "HTML & CSS",
//   "HTML & CSS",
//   "HTML & CSS",
//   "HTML & CSS",
//   "HTML & CSS",
//   "HTML & CSS",
//   "HTML & CSS",
//   "HTML & CSS",
//   "HTML & CSS",
//   "HTML & CSS in Malayalam",
//   "HTML & CSS Project",
//   "Introduction to Agile and Project Management",
//   "Introduction to Cloud Computing",
//   "Introduction to Data Engineering and Bigdata",
//   "Java",
//   "Java",
//   "Java",
//   "Java for Beginners in Bengali",
//   "JavaScript",
//   "JavaScript",
//   "JavaScript",
//   "JavaScript",
//   "JavaScript",
//   "JavaScript",
//   "JavaScript",
//   "JavaScript",
//   "JavaScript",
//   "Javascript for Beginners - Tamil",
//   "JavaScript Interview QnA",
//   "JCL",
//   "Jira: Jira project management",
//   "Jira: Jira project management",
//   "Jira: Jira project management - Arabic",
//   "Jira: Jira project management in Bengali",
//   "Jira: Jira project management Tamil",
//   "Jquery",
//   "jQuery - Beginners to Advanced",
//   "LangChain",
//   "Laravel",
//   "Machine Learning and AI Services in AWS",
//   "Mainframe",
//   "Mainframe in Tamil",
//   "Marketing Management & Strategies",
//   "Mastering Advanced Networking Concepts",
//   "Mastering Figma: Beginner to Expert UI/UX Design",
//   "Mastering Figma: Beginner to Expert UI/UX Design",
//   "Mastering Figma: Beginner to Expert UI/UX Design Hindi",
//   "Mastering Figma: Beginner to Expert UI/UX Design in Bengali",
//   "Mastering MongoDB in Telugu",
//   "Matlab",
//   "MATLAB and its Application",
//   "Matlab Mechanical",
//   "MEAN Stack Course",
//   "MEAN Stack Course",
//   "MEAN Stack Tamil",
//   "Microsoft Azure Architect Technologies - AZ 305 (Part - 2)",
//   "Microsoft Azure Architect Technologies - AZ 305 part 1",
//   "Microsoft Power Platform - Fundamentals",
//   "Mobile Hacking",
//   "Mobile Hacking - Tamil",
//   "My SQL",
//   "MySQL - Bengali",
//   "MySQL - Hindi",
//   "MySQL in Arabic",
//   "MySQL in French",
//   "MySQL in Swahili",
//   "MySQL in Telugu",
//   "MySQL Tamil",
//   "Natural Language Processing in French",
//   "Natural Language Processing in Python Telugu",
//   "Natural Language Processing using Python in Kannada",
//   "Natural Language Processing with Python",
//   "Natural Language Processing with Python - Tamil",
//   "Natural Language Processing with python in Hindi",
//   "Network and Security for IoT",
//   "Networking Essentials for Beginners",
//   "Nginx",
//   "NLG & NLU",
//   "Node Package Manager",
//   "Nuxt.js",
//   "Oracle",
//   "Oracle SQL",
//   "Oracle SQL - Bengali",
//   "Oracle SQL in Tamil",
//   "Personal Branding and Development for Entrepreneurs",
//   "PHP",
//   "PHP",
//   "PHP",
//   "PHP with MySQL",
//   "PL / SQL",
//   "PL/SQL - Hindi",
//   "PowerBI",
//   "Programming Fundamentals",
//   "Programming Fundamentals",
//   "Programming Fundamentals - French",
//   "Programming Fundamentals in Vietnamese",
//   "Programming Fundamentals Tamil",
//   "Project Management Fundamentals",
//   "Prompt Engineering for Teachers",
//   "Prompt Engineering For Teachers in Hindi",
//   "Prompt Engineering for Teachers in Tamil",
//   "Prompt Engineering for Teachers in Telugu",
//   "Puppet Fundamentals and Setup",
//   "Python Interview QnA",
//   "Python Project",
//   "Python Zero to Hero",
//   "Python Zero to Hero Bengali",
//   "Python Zero to Hero French",
//   "Python Zero to Hero in Hindi",
//   "Python Zero to Hero in Marathi",
//   "Python Zero to Hero in Tamil",
//   "Python Zero to Hero in Telugu",
//   "Python Zero to Hero vietnamese",
//   "R programming",
//   "Raspberry Pi for Beginners in Tamil",
//   "React JS",
//   "React JS - Kannada",
//   "React JS in French",
//   "React js in Tamil",
//   "React.js",
//   "React.js Bengali",
//   "React.js in Arabic",
//   "React.js in Hindi",
//   "React.js in Swahili",
//   "ReactJS in Telugu",
//   "Redis with Python RESTfull API",
//   "RPA Core",
//   "SAP Crash Course for Beginners",
//   "Scikit-Learn for Machine Learning Part-1",
//   "Selenium Automation with Python",
//   "Selenium Beginner",
//   "Selenium Beginner Tamil",
//   "Selenium Beginners - Bengali",
//   "Selenium Beginners in Arabic",
//   "Selenium for Beginners in French",
//   "Selenium for Beginners in Spanish",
//   "Selenium Java Advanced",
//   "Semiconductor Devices and Advancement",
//   "Servlets & JSP",
//   "Servlets & JSP",
//   "Sketch",
//   "SketchUp",
//   "Snowflake",
//   "Social Media and Content Techniques",
//   "Software Testing Life Cycle",
//   "Spring Boot",
//   "SQL Server",
//   "SQL Server in Tamil",
//   "Struts",
//   "Tableau Desktop",
//   "Tailwind CSS",
//   "Tekla Advanced",
//   "Virtual AI Assistant Powered by GPT3 API & Python",
//   "VSAM",
//   "Vue.js in French",
//   "Whisper API - Use Cases",
//   "Wordpress",
//   "C Sharp with Dotnet",
//   "Interview Preparation Toolkit for Freshers",
//   "HTML & CSS Interview QnA",
//   "Staad Pro Intermediate",
//   "Data Visualization in Python",
//   "C Plus Plus Programming",
//   "C Plus Plus Programming",
//   "Cyber Security & Ethical Hacking for Beginners",
//   "Data Analytics Using Pandas",
//   "Data Visualization with Matplotlib in Python",
//   "Deep learning using Pytorch",
//   "Deep learning using Pytorch",
//   "Introduction to Datascience with R",
//   "Jenkins For Automation Testers",
//   "Keras for Beginners",
//   "Keras for Beginners",
//   "Keras for Beginners",
//   "Kubernetes",
//   "Learn AWS Glue using PySpark by doing",
//   "RESTful APIs with Flask and Python",
//   "SolidWorks",
//   "AWS",
//   "AWS",
//   "AWS CloudFormation",
//   "AWS Lambda",
//   "AZ - 104",
//   "Blockchain",
//   "Blockchain",
//   "Blockchain",
//   "Blockchain",
//   "Codeigniter",
//   "Codeigniter",
//   "Cyber Security & Ethical Hacking - Advanced",
//   "Ethical Hacking Package",
//   "Games in Javascript",
//   "Google Assistant Using Nodemcu",
//   "Java 8",
//   "Jquery",
//   "Laravel",
//   "Lumen",
//   "Matlab",
//   "MySQL",
//   "OpenCart",
//   "Selenium Automation",
//   "Vue.js",
//   "Node.js",
//   "Web Development with Python Flask - Tamil",
//   "100 days of Javascript using codekata",
//   "Advanced Data Visualisation using PowerBI Tamil",
//   "Advanced Ethical Hacking",
//   "Advanced Ethical Hacking - Bengali",
//   "Advanced Ethical Hacking - Hindi",
//   "Ajax",
//   "Autodesk Inventor for Professional",
//   "Basics of Quantum Computing",
//   "Bootstrap",
//   "Bootstrap in French",
//   "Bootstrap in Marathi",
//   "Bootstrap in Telugu",
//   "Bootstrap Tamil",
//   "Business Communication",
//   "Canva",
//   "CATIA - Intermediate",
//   "CATIA Beginner",
//   "Chef Bundle",
//   "Civil 3D Course",
//   "Computational Thinking",
//   "Computational Thinking in Hindi",
//   "Confluence",
//   "Core Java in Telugu",
//   "Core Spring Fundamentals",
//   "Cyber Security for Beginners",
//   "Data visualization using Excel",
//   "Data Visualization using Excel - Tamil",
//   "Data Visualization using Python in French",
//   "Data Wrangling: Mastering Data Preparation and Analysis",
//   "Deep Learning Bundle",
//   "Developing Major Project using Django - Ecommerce website",
//   "Django basics concept & developing portfolio - Part 1 in Tamil",
//   "DSA using Java",
//   "DSA using python",
//   "DSA using Python in French",
//   "Dynamo Plugin",
//   "E - tabs - Intermediate English",
//   "E TABS Expert",
//   "E-TABS Advanced",
//   "E-Tabs Beginner",
//   "Embedded C Programming",
//   "Essentials of IoT",
//   "Fundamentals of MATLAB and Simulink",
//   "Game Development using Pygame",
//   "Games in JavaScript",
//   "Gatsby",
//   "Geometric Dimensioning and Tolerancing",
//   "Go for Beginners",
//   "GoDot - Beginners",
//   "Google Cloud Security Fundamentals",
//   "Graph QL with JS",
//   "GraphQL with JS Tamil",
//   "Introduction to containers in French",
//   "Introduction to Linux Shell Scripting with BASH",
//   "Java for Beginners in French",
//   "JavaScript Zero to Hero in French",
//   "JavaScript Zero to Hero in Swahili",
//   "JavaScript Zero to Hero in Telugu",
//   "jQuery - Beginners to Advanced",
//   "LinkedIn personal branding",
//   "Machine Learning 101",
//   "Marketing",
//   "Marketing Research Techniques",
//   "Marketing Research Techniques in Tamil",
//   "Mastering Data Preparation and Analysis",
//   "Mastering MongoDB",
//   "Mastering MongoDB - Hindi",
//   "Mastering MongoDB - Kannada",
//   "Mastering MongoDB in French",
//   "Mastering Puppet",
//   "Microcontrollers & Microprocessors",
//   "Mobile Application Security",
//   "MongoDB in Spanish",
//   "MS - PowerPoint",
//   "Next.js",
//   "Node JS - Hindi",
//   "Node.js in French",
//   "Node.js in Swahili",
//   "NodeJS - Tamil",
//   "NodeJS in Telugu",
//   "NxCAD Part-1",
//   "PCB designing using EasyEDA",
//   "Perl for Beginners",
//   "Practical Machine Learning",
//   "Practical Machine Learning",
//   "Practical Machine Learning in Arabic",
//   "Practical Machine Learning in French",
//   "Practical Machine Learning in Tamil",
//   "Product Thinking Masterclass and Taking an Idea to Product",
//   "Programming Fundamentals in Hindi",
//   "Proteus",
//   "Python Package",
//   "Python zero to hero - Swahili",
//   "Quantum Computing and its Application",
//   "Raspberry Pi Specialization",
//   "React.js Project",
//   "Redux",
//   "Rest APIs",
//   "Revit Architecture",
//   "REVIT MEP",
//   "Robotics",
//   "Ruby on Rails",
//   "Selenium Automation Training C#",
//   "Solidity - Smart Contracts",
//   "Solidity - Smart Contracts",
//   "Spring Boot in French",
//   "Spring Boot in Hindi",
//   "STAAD PRO - Advanced",
//   "STAAD PRO - Expert",
//   "Staad Pro Beginner",
//   "Tekla Foundation",
//   "The Ultimate Guide to Chef Software",
//   "UI Fundamentals",
//   "UI Fundamentals for Browser in Hindi",
//   "Unity",
//   "UX Fundamentals in Tamil",
//   "Vertex AI",
//   "VLSI",
//   "VRay",
//   "Vue.JS",
//   "Web Development with Python Flask",
//   "Web Development with Python Flask - Marathi",
//   "WEB3 JS",
//   "Wix",
//   "Wix in Tamil",
//   "Advanced Data Visualisation using PowerBI",
//   "Firebase",
//   "Functional Programming in JS",
//   "Tekla for Beginners",
//   "Web Development Bundle in Telugu",
//   "Django Framework Basic to Advance",
//   "System Design",
//   "Web Application Penetration Testing"
// ];

// const removeDuplicates = (arr) => {
//   return [...new Set(arr)];
// };

// const CourseList = () => {
//   const uniqueCourses = removeDuplicates(courses);
//   const uniqueCount = uniqueCourses.length; // Count of unique courses

//   return (
//     <div className="App">
//       <h1>Unique Course List</h1>
//       <h2>Total Unique Courses: {uniqueCount}</h2>
//       <ul>
//         {uniqueCourses.map((course, index) => (
//           <li key={index}>{course}</li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default CourseList;
